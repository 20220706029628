export type StemTypes = 'Primary Focal' | 'Secondary Focal' | 'Greenery' | 'Texture'

export enum ProductDetailKey {
  TYPE = 'TYPE',
  COLORS = 'COLORS',
  SHAPE = 'SHAPE',
  SEASON = 'SEASON',
  AVAILABILITY = 'AVAILABILITY',
  NOTES = 'NOTES',
  PUBLIC_NOTES = 'PUBLIC_NOTES',
  FORMULA_STYLE = 'FORMULA_STYLE',
  FORMULA_SIZE = 'FORMULA_SIZE',
  FORMULA_COLOR_COMPLEXITY = 'FORMULA_COLOR_COMPLEXITY'
}

export enum ProductImageType {
  DEFAULT = 'DEFAULT',
  INSPO = 'INSPO',
  RESULT = 'RESULT',
  DEPRECATED = 'DEPRECATED'
}

export enum InputFieldEnum {
  SUPPLIER_ID = 'supplier_id',
  QUANTITY = 'quantity',
  PRICE = 'price',
  DELIVERY_DATE = 'deliver_Date',
  ORDER_URL = 'order_url'
}

export enum PhotosNeeded {
  DESIGN_SIGN_OFF = 'DESIGN_SIGN_OFF',
  ALL_ITEMS_COMPLETED = 'ALL_ITEMS_COMPLETED',
  SETUP_COMPLETED = 'SETUP_COMPLETED',
  DELIVERY_COMPLETE = 'DELIVERY_COMPLETE'
}

export enum PreferenceKey {
  STYLE = 'STYLE',
  COLOR = 'COLOR',
  FLOWERS = 'FLOWERS',
  MICRO_STYLES = 'MICRO_STYLES',
  BOUQUET_SHAPE = 'BOUQUET_SHAPE'
}

export enum ProposalUnitType {
  BRIDAL_BOUQUET = 'BRIDAL_BOUQUET',
  BRIDESMAIDS_BOUQUET = 'BRIDESMAIDS_BOUQUET',
  BOUTS = 'BOUTS',
  CORSAGE = 'CORSAGE',
  BRIDAL_HAIR_PIECE = 'BRIDAL_HAIR_PIECE',
  FLOWER_GIRL_CROWN = 'FLOWER_GIRL_CROWN',
  AISLE_DECOR = 'AISLE_DECOR',
  CEREMONY_DECOR = 'CEREMONY_DECOR',
  CEREMONY_RENTAL = 'CEREMONY_RENTAL',
  COCKTAIL_DECOR = 'COCKTAIL_DECOR',
  FARM_TABLE_DECOR = 'FARM_TABLE_DECOR',
  ROUND_TABLE_DECOR = 'ROUND_TABLE_DECOR',
  GREENERY_TABLE_DECOR = 'GREENERY_TABLE_DECOR',
  CAKE_FLOWER = 'CAKE_FLOWER',
  SWEETHEART_TABLE_DECOR = 'SWEETHEART_TABLE_DECOR',
  SERVICE_LEVELS = 'SERVICE_LEVELS',
  CUSTOM = 'CUSTOM',
  GROOM_BOUTS = 'GROOM_BOUTS',
  WELCOME_SIGN_DECOR = 'WELCOME_SIGN_DECOR',
  MANTLE_DECOR = 'MANTLE_DECOR',
  RECEPTION_TABLE_DECOR = 'RECEPTION_TABLE_DECOR'
}

export enum ProposalUnitItemType {
  PERSONALS = 'PERSONALS',
  CEREMONY = 'CEREMONY',
  COCKTAIL = 'COCKTAIL',
  RECEPTION = 'RECEPTION',
  FEES = 'FEES'
}

export enum ExperienceType {
  NATIVE_PROPOSAL_V1 = 'NATIVE_PROPOSAL_V1',
  NATIVE_PROPOSAL_V2 = 'NATIVE_PROPOSAL_V2',
  GOOGLE_PROPOSAL_AUTO = 'GOOGLE_PROPOSAL_AUTO',
  MANUAL = 'MANUAL',
  PAYMENT_ONLY = 'PAYMENT_ONLY'
}

export enum OrderState {
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  CONFIRMED = 'CONFIRMED',
  BATCHED = 'BATCHED'
}

export enum SupplierType {
  WHOLESALE = 'WHOLESALE',
  FARM = 'FARM',
  HARDGOOD = 'HARDGOOD'
}

export enum EventCategory {
  PERSONALS = 'PERSONALS',
  CEREMONY = 'CEREMONY',
  COCKTAIL = 'COCKTAIL',
  RECEPTION = 'RECEPTION',
  FEES = 'FEES'
}

export enum UnitCategory {
  COUPLES_FLOWERS = 'COUPLES_FLOWERS',
  WEDDING_PARTY = 'WEDDING_PARTY',
  OTHER_PERSONALS = 'OTHER_PERSONALS',
  AISLE_DECOR = 'AISLE_DECOR',
  ALTAR_DECOR = 'ALTAR_DECOR',
  OTHER_CEREMONY = 'OTHER_CEREMONY',
  COCKTAIL_TABLE = 'COCKTAIL_TABLE',
  BAR_DECOR = 'BAR_DECOR',
  OTHER_COCKTAIL = 'OTHER_COCKTAIL',
  RECEPTION_TABLE = 'RECEPTION_TABLE',
  SWEETHEART_TABLE = 'SWEETHEART_TABLE',
  OTHER_RECEPTION = 'OTHER_RECEPTION'
}

export enum TemplateProposalUnitType {
  BRIDAL_BOUQUET = 'BRIDAL_BOUQUET',
  GROOM_BOUTS = 'GROOM_BOUTS',
  BRIDAL_HAIR_PIECE = 'BRIDAL_HAIR_PIECE',
  BRIDESMAIDS_BOUQUET = 'BRIDESMAIDS_BOUQUET',
  BOUTS = 'BOUTS',
  CORSAGE = 'CORSAGE',
  FLOWER_GIRL_CROWN = 'FLOWER_GIRL_CROWN',
  BASKET_PETALS = 'BASKET_PETALS',
  LOOSE_PETALS = 'LOOSE_PETALS',
  ARCH_CLUSTER = 'ARCH_CLUSTER',
  CLOUD = 'CLOUD',
  POSIES = 'POSIES',
  WELCOME_SIGN_DECOR = 'WELCOME_SIGN_DECOR',
  CHAIR_FLOWER = 'CHAIR_FLOWER',
  MANTLE_DECOR = 'MANTLE_DECOR',
  BUD_VASE = 'BUD_VASE',
  ROUND_GREENERY = 'ROUND_GREENERY',
  FARM_GREENERY = 'FARM_GREENERY',
  CENTERPIECE = 'CENTERPIECE',
  GARLAND = 'GARLAND',
  CAKE_FLOWER = 'CAKE_FLOWER',
  CUSTOM = 'CUSTOM',
  EXTRA_FEES = 'EXTRA_FEES',

  // v1
  AISLE_DECOR = 'AISLE_DECOR',
  CEREMONY_DECOR = 'CEREMONY_DECOR',
  CEREMONY_RENTAL = 'CEREMONY_RENTAL',
  COCKTAIL_DECOR = 'COCKTAIL_DECOR',
  FARM_TABLE_DECOR = 'FARM_TABLE_DECOR',
  ROUND_TABLE_DECOR = 'ROUND_TABLE_DECOR',
  GREENERY_TABLE_DECOR = 'GREENERY_TABLE_DECOR',
  SWEETHEART_TABLE_DECOR = 'SWEETHEART_TABLE_DECOR',
  SERVICE_LEVELS = 'SERVICE_LEVELS',
  RECEPTION_TABLE_DECOR = 'RECEPTION_TABLE_DECOR',
  LONG_TABLE_DECOR = 'LONG_TABLE_DECOR'
}

export enum ProposalUnitProductType {
  BRIDAL_BOUQUET = 'BRIDAL_BOUQUET',
  GROOM_BOUTS = 'GROOM_BOUTS',
  BRIDAL_HAIR_PIECE = 'BRIDAL_HAIR_PIECE',
  BRIDESMAIDS_BOUQUET = 'BRIDESMAIDS_BOUQUET',
  BOUTS = 'BOUTS',
  CORSAGE = 'CORSAGE',
  FLOWER_GIRL_CROWN = 'FLOWER_GIRL_CROWN',
  BASKET_PETALS = 'BASKET_PETALS',
  LOOSE_PETALS = 'LOOSE_PETALS',
  ARCH_CLUSTER = 'ARCH_CLUSTER',
  CLOUD = 'CLOUD',
  POSIES = 'POSIES',
  WELCOME_SIGN_DECOR = 'WELCOME_SIGN_DECOR',
  CHAIR_FLOWER = 'CHAIR_FLOWER',
  MANTLE_DECOR = 'MANTLE_DECOR',
  BUD_VASE = 'BUD_VASE',
  ROUND_GREENERY = 'ROUND_GREENERY',
  FARM_GREENERY = 'FARM_GREENERY',
  CENTERPIECE = 'CENTERPIECE',
  GARLAND = 'GARLAND',
  CAKE_FLOWER = 'CAKE_FLOWER',
  CUSTOM = 'CUSTOM'
}

export enum TemplateProductType {
  FULL_PROPOSAL = 'FULL_PROPOSAL',
  PREMERCHANDIZED = 'PREMERCHANDIZED'
}

export enum StemErrorStatus {
  NONE = 'NONE',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

export enum OrderStemIssueKey {
  WRONG_COLOR = 'WRONG_COLOR',
  DAMAGED = 'DAMAGED',
  MISSING_SOME = 'MISSING_SOME',
  MISSING_ALL = 'MISSING_ALL',
  TOO_MANY = 'TOO_MANY',
  WRONG_STEM = 'WRONG_STEM'
}

export enum EventSummaryTab {
  RECIPES = 'recipes',
  ORDERS = 'orders',
  PHOTOS = 'photos'
}

export enum InvoiceStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  PAYMENT_FAILED = 'PAYMENT_FAILED'
}

export enum ServiceLevelText {
  DELIVERY = 'Just Delivery',
  DELIVERY_INSTALL = 'Delivery & Installation',
  FULL_SERVICE = 'Full Service',
  DROP_OFF_ONLY = 'Drop Off Only',
  SERVICE_AT_ONE_VENUE = 'Service at One Venue',
  SERVICE_AT_TWO_VENUES = 'Service at Two Venues'
}

export enum ServiceLevel {
  DELIVERY = 'DELIVERY',
  DELIVERY_INSTALL = 'DELIVERY_INSTALL',
  FULL_SERVICE = 'FULL_SERVICE',
  DROP_OFF_ONLY = 'DROP_OFF_ONLY',
  SERVICE_AT_ONE_VENUE = 'SERVICE_AT_ONE_VENUE',
  SERVICE_AT_TWO_VENUES = 'SERVICE_AT_TWO_VENUES'
}

export enum ProductReSourcedType {
  STEM = 'STEM',
  HARD_GOOD = 'HARD_GOOD'
}

export enum DesignGuideVersion {
  DG_V1 = 'DG_V1',
  DG_V2 = 'DG_V2'
}
export enum DesignGuideStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE'
}

export enum RecipeFormulaStyle {
  CLASSIC = 'CLASSIC',
  LUSH = 'LUSH',
  WILDFLOWER = 'WILDFLOWER',
  ASYMMETRICAL = 'ASYMMETRICAL'
}

export enum RecipeFormulaStyleV2 {
  CLASSIC = 'CLASSIC',
  LUSH = 'LUSH',
  WILDFLOWER = 'WILDFLOWER',
  ASYMMETRICAL = 'ASYMMETRICAL',
  NO_STYLE = 'NO_STYLE'
}

export enum RecipeFormulaSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export enum RecipeFormulaStemRole {
  FOCAL = 'FOCAL',
  SECONDARY = 'SECONDARY',
  FILLER = 'FILLER',
  LINEAR = 'LINEAR',
  GREENERY = 'GREENERY',
  SPECIALTY = 'SPECIALTY'
}

export enum EventLocationType {
  CEREMONY = 'CEREMONY',
  RECEPTION = 'RECEPTION',
  OTHER = 'OTHER',
  DELIVERY = 'DELIVERY',
  VENUE = 'VENUE'
}

export enum EventServiceType {
  REHEARSAL_DINNER = 'REHEARSAL_DINNER',
  DROP_OFF = 'DROP_OFF',
  EARLY_DELIVERY = 'EARLY_DELIVERY',
  CEREMONY_INSTALL = 'CEREMONY_INSTALL',
  FLIP = 'FLIP',
  RECEPTION_INSTALL = 'RECEPTION_INSTALL',
  BREAKDOWN = 'BREAKDOWN'
}
